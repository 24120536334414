const {
  VUE_APP_VIBER_BOT_URI,
  VUE_APP_TG_BOT_ID,
  VUE_APP_WA_BOT_NUMBER,
  VUE_APP_WA_PREFIX,
} = process.env;

export default [
  {
    name: "viber",
    url: `viber://pa?chatURI=${VUE_APP_VIBER_BOT_URI as string}`,
    terminalPrefix: "context",
  },
  {
    name: "telegram",
    url: `https://t.me/${VUE_APP_TG_BOT_ID as string}`,
    terminalPrefix: "start",
  },
  {
    name: "whats-app",
    url: `https://wa.me/${VUE_APP_WA_BOT_NUMBER as string}`,
    terminalPrefix: "text",
    queryPrefix: encodeURI(VUE_APP_WA_PREFIX),
  },
];
