
import { mapState } from "vuex";
import { defineComponent } from "vue";
import ChatBotLinks from "@/components/ChatBotLinks.vue";
import MobileAppsLinks from "@/components/MobileAppsLinks.vue";
import AppLinkButton from "@/components/AppLinkButton.vue";
import store from "@/store";

export default defineComponent({
  name: "HomeView",
  components: {
    MobileAppsLinks,
    AppLinkButton,
    ChatBotLinks,
  },
  computed: {
    ...mapState(["showMobileLinks"]),
  },
  methods: {
    toPayService: () => {
      store.dispatch("goToPayService");
    },
  },
});
