<template>
  <a class="app-link-button" v-bind="$attrs">
    <slot class="app-link-button__prepend" name="prepend"></slot>
    <slot></slot>
    <slot class="app-link-button__append" name="append"></slot>
  </a>
</template>

<script>
export default {
  name: "AppLinkButton",
};
</script>

<style lang="scss" scoped>
.app-link-button {
  font-family: "Poppins-Regular", sans-serif;
  border-radius: $border-radius;
  border: 1px solid $border-color;
  background-color: $app-bg-color;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1.5rem;
  margin: 1rem 0;
}
</style>
