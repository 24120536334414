import { createStore } from "vuex";
import currentAppBots from "./helpers/currentAppBots";
import { chatBotData, rootState } from "@/store/interfaces";
import axios, { AxiosResponse } from "axios";

const rootState = (): rootState => {
  return {
    query: {},
    loading: true,
    selectedBot: {
      name: "",
      img: "",
      url: "",
      env: "",
    },
    chatBotLinks: [],
    showMobileLinks: process.env.VUE_APP_SHOW_MOBILE_LINKS,
  };
};

export default createStore({
  state: rootState,
  getters: {},
  mutations: {
    SET_LOADING(state, newLoading: boolean) {
      state.loading = newLoading;
    },
    SET_QUERY(state, query = {}) {
      if (query) {
        state.query = query;
      }
    },
    SET_SELECTED_BOT(state, currBot = {}) {
      if (currBot?.url) {
        localStorage.setItem("selectedChatBot", JSON.stringify(currBot));
        state.selectedBot = currBot;
      } else {
        localStorage.setItem(
          "selectedChatBot",
          JSON.stringify({
            name: "",
            img: "",
            url: "",
            env: "",
          })
        );
      }
    },
    SET_SHOW_MOBILE_LINKS(state, showMobileLinks = {}) {
      if (showMobileLinks) {
        state.showMobileLinks = showMobileLinks;
      }
    },
  },
  actions: {
    async goToPayService({ state }) {
      const { query } = state;
      const terminalCode = query?.terminalCode || query?.t;
      if (terminalCode) {
        const { VUE_APP_LOYALTY_PROGRAM } = process.env;
        window.location.href = `https://pay.bms.group/?c=${
          query?.idLoyaltyProgram ?? VUE_APP_LOYALTY_PROGRAM
        }&f=e_c&t=${terminalCode}`;
      }
    },

    async generateChatBotLinksData({ state }): Promise<chatBotData[]> {
      this.commit("SET_LOADING", true);
      try {
        const { query } = state;

        const terminalCode = query?.terminalCode || query?.t;

        if (!terminalCode) {
          return (state.chatBotLinks = []);
        }

        const {
          VUE_APP_LOYALTY_PROGRAM,
          VUE_APP_FURCA_URL,
          VUE_APP_ACTIVE_BOTS,
        } = process.env;

        const furcaBody = {
          idLoyaltyProgram: VUE_APP_LOYALTY_PROGRAM,
          ...query,
          terminalCode,
        };

        const furcaResponse = await axios.post<
          AxiosResponse<{ token: string }>
        >(`${VUE_APP_FURCA_URL}api/users`, furcaBody);

        const token = furcaResponse?.data?.data?.token || "";

        const activeBots = JSON.parse(VUE_APP_ACTIVE_BOTS);

        console.log(`activeBots are ${activeBots}`);

        state.chatBotLinks = currentAppBots.reduce(
          (
            acc: chatBotData[],
            { url, terminalPrefix, name, queryPrefix }
          ): chatBotData[] => {
            const delimiter = /\?/.test(url) ? "&" : "?";

            if (url && activeBots.includes(name)) {
              acc.push({
                url: `${url}${delimiter}${terminalPrefix}=${
                  queryPrefix ?? ""
                }${token}`,
                img: `${name}-icon.svg`,
                name,
                env: VUE_APP_LOYALTY_PROGRAM,
              });
            }

            return acc;
          },
          []
        );
        return state.chatBotLinks;
      } finally {
        this.commit("SET_LOADING", false);
      }
    },
  },

  modules: {},
});
