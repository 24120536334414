<template>
  <div v-if="loading" class="chat-bot-loader-wrapper">
    <LinkLoader />
  </div>
  <div class="chat-bot-links" v-if="chatBotLinks.length">
    <span class="chat-bot-links__title"
      ><h2>Чат-бот:</h2>
      оплата заказов, накопление бонусов</span
    >
    <div class="chat-bot-links__options-list" @click="hrefClickHandle($event)">
      <a
        v-for="item in chatBotLinks"
        :key="item.url"
        class="chat-bot-links__options-list__item"
        target="_blank"
        :href="item.url"
      >
        <img :src="require(`@/assets/img/${item.img}`)" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";
import LinkLoader from "./LinkLoader.vue";

export default {
  name: "ChatBotLinks",
  components: {
    LinkLoader,
  },
  computed: {
    ...mapState(["chatBotLinks", "selectedBot", "loading"]),
  },
  methods: {
    findChatBot: function (linkUrl) {
      // массив с разрешенными урлами нужен на случай если какой то бот отключат, а он уже сохранен у юзера в устройстве
      // или же приложение запущено с другим env

      return this.chatBotLinks.filter((item) => {
        return item.url === linkUrl;
      })[0];
    },
    hrefClickHandle: function (e) {
      e.preventDefault();
      const href = e.target.closest("a")?.href;
      const currBot = Object.assign({}, this.findChatBot(href));
      store.commit("SET_SELECTED_BOT", currBot);
      if (currBot?.url) {
        window.location.href = currBot.url;
      }
    },
  },
  mounted() {
    const selectedBotUrl = this.selectedBot?.url;
    const findBot = Object.assign({}, this.findChatBot(selectedBotUrl));

    if (findBot?.url) {
      store.dispatch("goToPayService");
    }
  },
};
</script>

<style lang="scss">
.chat-bot-links {
  font-size: 1.2em;
  padding: 1rem;
  margin: 1em 0;
  border-radius: $border-radius;
  min-height: 100px;
  @extend %app-box-shadow;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  &__title {
    text-align: center;
  }
  &__options-list {
    margin: 1rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;
    &__item {
      $item-value: 4rem;
      height: $item-value;
      width: $item-value;

      @media (min-width: 375px) {
        $item-value: 5rem;
        height: $item-value;
        width: $item-value;
      }

      margin: 1rem;
      img {
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }
}
</style>
