<template>
  <div class="mobile-app-links">
    <span class="mobile-app-links__title">
      <b>Мобильное приложение: </b>
      удобная оплата, трата <br />
      и накопление бонусов, актуальные новости
    </span>
    <div class="mobile-app-links__options-list">
      <a
        class="mobile-app-links__options-list__item"
        href="https://zilf.app.link/sBTk8obgfAb"
      >
        <img src="@/assets/img/app-store-icon.svg" />
      </a>

      <a
        class="mobile-app-links__options-list__item"
        href="https://play.google.com/store/apps/details?id=com.bmscard.citilab"
      >
        <img src="@/assets/img/google-play-icon.svg" />
      </a>

      <a
        class="mobile-app-links__options-list__item"
        href="https://apps.rustore.ru/app/com.bmscard.citilab"
      >
        <img src="@/assets/img/ru-store-icon.svg" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileAppsLinks",
};
</script>

<style lang="scss">
.mobile-app-links {
  font-size: 1.2em;
  padding: 1rem;
  margin: 1em 0;
  border-radius: $border-radius;
  border: 1px solid $border-color;
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  &__title {
    text-align: center;
  }
  &__options-list {
    margin: 1rem 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &__item {
      margin: 0.3rem;
      border-radius: 5px;
      overflow: hidden;

      $item-value: 3rem;
      height: $item-value;
      width: $item-value * 3.2;

      @media (min-width: 500px) {
        $item-value: 4rem;
        height: $item-value;
        width: $item-value * 3.2;
      }

      img {
        object-fit: cover;
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }
}
</style>
