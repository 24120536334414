<template>
  <router-view v-if="query.terminalCode || query.t" />
  <h1 v-else>Не валидная ссылка</h1>
</template>

<script>
import { computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    const query = computed(() => route.query);
    const selectedChatBot = JSON.parse(localStorage.getItem("selectedChatBot"));

    store.commit("SET_SELECTED_BOT", selectedChatBot);

    watch(query, (value) => {
      store.commit("SET_QUERY", value);
      store.dispatch("generateChatBotLinksData");
    });
    return { query };
  },
};
</script>

<style lang="scss">
h1 {
  text-align: center;
  font-size: 1.5em;
}
</style>
